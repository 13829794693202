/**
 *  Bootstrap print 3.1.5 for Bootstrap v3.
 *
 *  Use Extra small devices (xs) style in print.
 *
 *  Mange grids using Bootstrap v3 for printed media.
 *  This will help controlling grid columns size on printed pages.
 *  https://github.com/Vardot/bootstrap-print
 * 
 *  Require:
 *    - twbs/bootstrap: https://github.com/twbs/bootstrap
 */

@media print {

  //  We need to import variables and mixins first.
  // @import '../bootstrap/less/variables';
  // @import '../bootstrap/less/mixins/grid-framework';
  // @import '../bootstrap/less/mixins/grid';

  // Extra small devices (xs) style in print.
  .make-grid(xs);

}
