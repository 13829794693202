/**
 * Variable Overrides.
 *
 * Modify this file to override the Bootstrap Framework variables. You can copy
 * these variables directly from ../bootstrap/less/variables.less to this file.
 */

//== Colors
//
//## Gray and brand colors for use across Bootstrap.


@import "../newcss/variables.less";

@gray-base: #000;
@gray-darker: lighten(@gray-base, 13.5%);
@gray-dark: lighten(@gray-base, 20%);
@gray: #707070;
@gray-light: #cccccc;
@gray-lighter: #e5e5e5;

@brand-primary: @apresge_darkgreen;
@brand-success: #5cb85c;
@brand-info: #5bc0de;
@brand-warning: #f0ad4e;
@brand-danger: #d9534f;





//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
@body-bg: #fff;
//** Global text color on `<body>`.
@text-color: @gray-base;

//** Global textual link color.
@link-color: #930084;
//** Link hover color set via `darken()` function.
@link-hover-color: @gray;
//** Link hover decoration.
@link-hover-decoration: none;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

@font-family-sans-serif: 'Arial Black', Gadget, sans-serif;
@font-family-serif: Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
@font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base: "Helvetica Neue", Helvetica, Arial, sans-serif;

@font-size-base: 12px;
@font-size-large: 15px;
@font-size-small: 11px;

@font-size-h1: floor((@font-size-base * 1.7)); // ~20px
@font-size-h2: floor((@font-size-base * 1.5)); // ~18px
@font-size-h3: @font-size-base;
@font-size-h4: ceil((@font-size-base * 1.2)); // ~14.4px
@font-size-h5: @font-size-base;
@font-size-h6: @font-size-base;

//** Unit-less `line-height` for use in components like buttons.
@line-height-base: 1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
@line-height-computed: floor((@font-size-base * @line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
@headings-font-family: inherit;
@headings-font-weight: 700;
@headings-line-height: 1.3;
@headings-color: inherit;


//== Iconography

// Set the proper directory for the Bootstrap Glyphicon font.
@icon-font-path: '../bootstrap/fonts/';

//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

@padding-base-vertical: 6px;
@padding-base-horizontal: 12px;

@padding-large-vertical: 10px;
@padding-large-horizontal: 16px;

@padding-small-vertical: 5px;
@padding-small-horizontal: 10px;

@padding-xs-vertical: 1px;
@padding-xs-horizontal: 5px;

@line-height-large: 1.3333333; // extra decimals for Win 8.1 Chrome
@line-height-small: 1.5;

@border-radius-base: 0px;
@border-radius-large: 6px;
@border-radius-small: 3px;

//** Global color for active items (e.g., navs or dropdowns).
@component-active-color: #fff;
//** Global background color for active items (e.g., navs or dropdowns).
@component-active-bg: @brand-primary;

//** Width of the `border` for generating carets that indicate dropdowns.
@caret-width-base: 4px;
//** Carets increase slightly in size for larger components.
@caret-width-large: 5px;


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

@btn-font-weight: normal;

@btn-default-color: #fff;
@btn-default-bg: #930084;
@btn-default-border: transparent;

@btn-primary-color: #fff;
@btn-primary-bg: @brand-primary;
@btn-primary-border: #fff;

@btn-success-color: #fff;
@btn-success-bg: @brand-success;
@btn-success-border: darken(@btn-success-bg, 5%);

@btn-info-color: #fff;
@btn-info-bg: @brand-info;
@btn-info-border: darken(@btn-info-bg, 5%);

@btn-warning-color: #fff;
@btn-warning-bg: @brand-warning;
@btn-warning-border: darken(@btn-warning-bg, 5%);

@btn-danger-color: #fff;
@btn-danger-bg: @brand-danger;
@btn-danger-border: darken(@btn-danger-bg, 5%);

@btn-link-disabled-color: @gray-light;

// Allows for customizing button radius independently from global border radius
@btn-border-radius-base: 4px;
@btn-border-radius-large: @border-radius-large;
@btn-border-radius-small: @border-radius-small;


//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
@dropdown-bg: #fff;
//** Dropdown menu `border-color`.
@dropdown-border: rgba(0, 0, 0, .15);
//** Dropdown menu `border-color` **for IE8**.
@dropdown-fallback-border: #ccc;
//** Divider color for between dropdown items.
@dropdown-divider-bg: #e5e5e5;

//** Dropdown link text color.
@dropdown-link-color: #930084;
//** Hover color for dropdown links.
@dropdown-link-hover-color: @gray;
//** Hover background for dropdown links.
@dropdown-link-hover-bg: #f5f5f5;

//** Active dropdown menu item text color.
@dropdown-link-active-color: @component-active-color;
//** Active dropdown menu item background color.
@dropdown-link-active-bg: @component-active-bg;

//** Disabled dropdown menu item background color.
@dropdown-link-disabled-color: @gray-light;

//** Text color for headers within dropdown menus.
@dropdown-header-color: @brand-primary;

//** Deprecated `@dropdown-caret-color` as of v3.1.0
@dropdown-caret-color: @brand-primary;

//== Navbar
//
//##

// Basics of a navbar
@navbar-height: 40px;
@navbar-margin-bottom: @line-height-computed;
@navbar-border-radius: @border-radius-base;
@navbar-padding-horizontal: floor((@grid-gutter-width / 2));
@navbar-padding-vertical: ((@navbar-height - @line-height-computed) / 2);
@navbar-collapse-max-height: 340px;

@navbar-default-color: #fff;
@navbar-default-bg: @brand-primary;
@navbar-default-border: transparent;

// Navbar links
@navbar-default-link-color: #ffffff;
@navbar-default-link-hover-color: #fff;
@navbar-default-link-hover-bg: #930084;
@navbar-default-link-active-color: #FFF;
@navbar-default-link-active-bg: #930084;
@navbar-default-link-disabled-color: #ccc;
@navbar-default-link-disabled-bg: transparent;

// Navbar brand label
@navbar-default-brand-color: @navbar-default-link-color;
@navbar-default-brand-hover-color: darken(@navbar-default-brand-color, 10%);
@navbar-default-brand-hover-bg: transparent;

// Navbar toggle
@navbar-default-toggle-hover-bg: transparent;
@navbar-default-toggle-icon-bar-bg: #fff;
@navbar-default-toggle-border-color: transparent;

//=== Inverted navbar
// Reset inverted navbar basics
@navbar-inverse-color: @gray;
@navbar-inverse-bg: transparent;
@navbar-inverse-border: transparent;

// Inverted navbar links
@navbar-inverse-link-color: @gray;
@navbar-inverse-link-hover-color: #930084;
@navbar-inverse-link-hover-bg: transparent;
@navbar-inverse-link-active-color: #fff;
@navbar-inverse-link-active-bg: @navbar-inverse-link-color;
@navbar-inverse-link-disabled-color: #444;
@navbar-inverse-link-disabled-bg: transparent;

// Inverted navbar brand label
@navbar-inverse-brand-color: @navbar-default-brand-color;
@navbar-inverse-brand-hover-color: @navbar-default-brand-hover-color;
@navbar-inverse-brand-hover-bg: transparent;

// Inverted navbar toggle
@navbar-inverse-toggle-hover-bg: transparent;
@navbar-inverse-toggle-icon-bar-bg: #fff;
@navbar-inverse-toggle-border-color: transparent;


//== Navs
//
//##

//=== Shared nav styles
@nav-link-padding: 10px 15px;
@nav-link-hover-bg: transparent;

@nav-disabled-link-color: @gray-light;
@nav-disabled-link-hover-color: @gray-light;

//== Tabs
@nav-tabs-border-color: #ddd;

@nav-tabs-link-hover-border-color: @gray-lighter;

@nav-tabs-active-link-hover-bg: @body-bg;
@nav-tabs-active-link-hover-color: @gray;
@nav-tabs-active-link-hover-border-color: #ddd;

@nav-tabs-justified-link-border-color: #ddd;
@nav-tabs-justified-active-link-border-color: @body-bg;

//== Pills
@nav-pills-border-radius: @border-radius-base;
@nav-pills-active-link-hover-bg: @component-active-bg;
@nav-pills-active-link-hover-color: @component-active-color;

//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
@grid-columns: 12;
//** Padding between columns. Gets divided in half for the left and right.
@grid-gutter-width: 30px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
@grid-float-breakpoint: @screen-md-min;
//** Point at which the navbar begins collapsing.
@grid-float-breakpoint-max: (@screen-md-min - 1);

//== List group
//
//##

//** Background color on `.list-group-item`
@list-group-bg: transparent;
//** `.list-group-item` border color
@list-group-border: @gray-light;
//** List group border radius
@list-group-border-radius: @border-radius-base;

//** Background color of single list items on hover
@list-group-hover-bg: #f5f5f5;
//** Text color of active list items
@list-group-active-color: @component-active-color;
//** Background color of active list items
@list-group-active-bg: @component-active-bg;
//** Border color of active list elements
@list-group-active-border: @list-group-active-bg;
//** Text color for content within active list items
@list-group-active-text-color: lighten(@list-group-active-bg, 40%);

//** Text color of disabled list items
@list-group-disabled-color: @gray-light;
//** Background color of disabled list items
@list-group-disabled-bg: @gray-lighter;
//** Text color for content within disabled list items
@list-group-disabled-text-color: @list-group-disabled-color;

@list-group-link-color: #555;
@list-group-link-hover-color: @list-group-link-color;
@list-group-link-heading-color: #333;

//== Panels
//
//##

@panel-bg: #fff;
@panel-body-padding: 15px;
@panel-heading-padding: 10px 15px;
@panel-footer-padding: @panel-heading-padding;
@panel-border-radius: @border-radius-base;

//** Border color for elements within panels
@panel-inner-border: #ddd;
@panel-footer-bg: #f5f5f5;

@panel-default-text: @gray-dark;
@panel-default-border: #ddd;
@panel-default-heading-bg: #f5f5f5;

@panel-primary-text: #fff;
@panel-primary-border: @brand-primary;
@panel-primary-heading-bg: @brand-primary;

@panel-success-text: @state-success-text;
@panel-success-border: @state-success-border;
@panel-success-heading-bg: @state-success-bg;

@panel-info-text: @state-info-text;
@panel-info-border: @state-info-border;
@panel-info-heading-bg: @state-info-bg;

@panel-warning-text: @state-warning-text;
@panel-warning-border: @state-warning-border;
@panel-warning-heading-bg: @state-warning-bg;

@panel-danger-text: @state-danger-text;
@panel-danger-border: @state-danger-border;
@panel-danger-heading-bg: @state-danger-bg;

//== Breadcrumbs
//
//##

@breadcrumb-padding-vertical: 8px;
@breadcrumb-padding-horizontal: 15px;
//** Breadcrumb background color
@breadcrumb-bg: #f5f5f5;
//** Breadcrumb text color
@breadcrumb-color: #ccc;
//** Text color of current page in the breadcrumb
@breadcrumb-active-color: @brand-primary;
//** Textual separator for between breadcrumb elements
@breadcrumb-separator: "❯";
