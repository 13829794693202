/**
 * @file
 * Styles for Flexslider.
 */
.flexslider {
	background:transparent none;
	border:0 none;
	border-radius: 0 0 0 0;
	box-shadow:none;
	margin:0;
	padding:0;
  position:relative;
  zoom: 1;
}

.flexslider .slides {
  margin:0;
  padding:0;
  zoom:1;
  display:block;
}

.flexslider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.flexslider .slides > li {
  margin:0;
  padding:0;
  display: none;
  -webkit-backface-visibility: hidden;
}

.flexslider .slides img {
  width: 100%;
  display: block;
}

/**
 * Breves
 */
.flex-breves-flexslider {
  padding: 0;
  margin-bottom: @padding-base-vertical;

  .flex-breves-viewport {
    margin: 0 44px;

    ul.slides>li {
      .background-stripped();
      position: relative;
      margin: 0 floor((@grid-gutter-width / 2)) 0 0;
      padding-bottom: 30px;

      &:first-child {
        margin-left:0;
      }
      &:last-child {
        margin-left:0;
      }

      .title--breve {
        .black-font();
        color: @gray-base;
        font-size: @font-size-base;
        margin: 15px;
      }
      .content--breve {
        padding: 0 15px;
      }
      .link-more {
        position: absolute;
        bottom: 6px;
        right: 15px;
        a {
          font-weight: bold;
          color: @gray;
        }
      }
    }
  }
}
.flex-breves-direction-nav {
  list-style:none;
  margin:0;
  padding:0;
  font-size: 32px;
  line-height:1;

  a {
    z-index: 10;
    cursor: pointer;
    text-decoration: none;
    display: block;
    position: absolute;
    top: 50%;
    width: 32px;
    height: 32px;
    .translate3d(0; -50%; 0);
    text-indent: 9999px;
    overflow: hidden;
    color: #797875;

    &:focus, &:hover {
      color: #000;
    }

    &:before {
      font-family: 'FontAwesome';
      position: absolute;
      text-indent: 0;
      top: 0;
    }

    &.flex-breves-prev {
      left: 0;
      &:before {
        content: "\f053";
        left:0;
      }
    }

    &.flex-breves-next {
      right: 0;
      &:before {
        content: "\f054";
        right: 0;
      }
    }
  }
}
