/**
 *  Bootstrap print 3.1.5 for Bootstrap v3.
 *
 *  Mange grids using Bootstrap v3 for printed media.
 *  This will help controlling grid columns size on printed pages.
 *  https://github.com/Vardot/bootstrap-print
 *
 *  Require:
 *    - twbs/bootstrap: https://github.com/twbs/bootstrap
 */

 .make-print-grid-columns() {
  // Common styles for all sizes of grid columns, widths 1-12
  .col(@index) { // initial
    @item: ~".col-p-@{index}";
    .col((@index + 1), @item);
  }
  .col(@index, @list) when (@index =< @grid-columns) { // general; "=<" isn't a typo
    @item: ~".col-p-@{index}";
    .col((@index + 1), ~"@{list}, @{item}");
  }
  .col(@index, @list) when (@index > @grid-columns) { // terminal
    @{list} {
      float: left;
      position: relative;
      // Prevent columns from collapsing when empty
      min-height: 1px;
      // Inner gutter via padding
      padding-left:  ceil((@grid-gutter-width / 2));
      padding-right: floor((@grid-gutter-width / 2));
    }
  }
  .col(1); // kickstart it
}

// Create grid for specific class
.make-print-grid() {
  .make-print-grid-columns();
  .loop-grid-columns(@grid-columns, p, width);
  .loop-grid-columns(@grid-columns, p, pull);
  .loop-grid-columns(@grid-columns, p, push);
  .loop-grid-columns(@grid-columns, p, offset);
}

@media print {

  //  We need to import variables and mixins first.
  // @import '../bootstrap/less/variables';
  // @import '../bootstrap/less/mixins/grid-framework';
  // @import '../bootstrap/less/mixins/grid';

  // Make print grid to work with the bootstrap variables.
  .make-print-grid();

   /* Always insert a page break after the element */
  .always-page-break-after,
  .always-pba {
    page-break-after: always;
  }

  /* Avoid page break after the element (if possible) */
  .avoid-page-break-after,
  .avoid-pba {
    page-break-after: avoid;
  }

  /* Insert page breaks after the element so that the next page is formatted as a left page */
  .left-page-break-after,
  .left-pba{
    page-break-after: left;
  }

  /* Insert page breaks after the element so that the next page is formatted as a right page */
  .right-page-break-after,
  .right-pba{
    page-break-after: right;
  }

  /* Always insert a page break before the element */
  .always-page-break-before,
  .always-pbb{
    page-break-before: always;
  }

  /* Avoid page break before the element (if possible) */
  .avoid-page-break-before,
  .avoid-pbb {
    page-break-before: avoid;
  }

  /* Insert page breaks before the element so that the next page is formatted as a left page */
  .left-page-break-before,
  .left-pbb{
    page-break-before: left;
  }

  /* Insert page breaks before the element so that the next page is formatted as a right page */
  .right-page-break-before,
  .right-pbb{
    page-break-before: right;
  }

  /* Avoid page break inside the element (if possible) */
  .avoid-page-break-inside,
  .avoid-pbi {
    page-break-inside: avoid;
    width: 3;
  }
  
  /* Hide link's href URL in print. */
  a[href]:after {
    content: none !important;
  }

}
