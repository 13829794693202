// Background
.background-stripped() {
    background: transparent url(../images/background.gif) left top repeat;
}

.button-variant-apres(@color;
    @background;
    @border;
    @hover-color;
    @hover-background;

    @hover-border) {
    color: @color;
    background-color: @background;
    border-color: @border;

    &:focus,
    &.focus {
        color: @hover-color;
        background-color: @hover-background;
        border-color: @hover-border;
    }

    &:hover {
        color: @hover-color;
        background-color: @hover-background;
        border-color: @hover-border;
    }

    &:active,
    &.active,
    .open>.dropdown-toggle& {
        color: @hover-color;
        background-color: @hover-background;
        border-color: @hover-border;

        &:hover,
        &:focus,
        &.focus {
            color: @hover-color;
            background-color: darken(@hover-background, 17%);
            border-color: darken(@hover-border, 25%);
        }
    }

    &:active,
    &.active,
    .open>.dropdown-toggle& {
        background-image: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {

        &:hover,
        &:focus,
        &.focus {
            background-color: @background;
            border-color: @border;
        }
    }

    .badge {
        color: @background;
        background-color: @color;
    }
}

.black-font() {
    font-family: @font-family-sans-serif;
    font-style: normal;
    font-weight: 900;
    letter-spacing: 0;
}

.title-inline() {
    display: inline-block;
    margin-top: 0;
    margin-right: 15px;
}

.post-date-inline() {
    display: inline-block;
    margin-top: 0;
    margin-right: 5px;
}


/**
 * HTML
 */

body {
    .background-stripped();
}

.mce-content-body {
    background: #fff none;
}

h1,
.h1 {
    color: #930084;
    margin-top: 0;
}

h2,
.h2 {
    color: @apresge_darkgreen;
    margin-top: 0;
}

h3,
.h3 {
    color: @gray;
    margin-top: 0;
}

h4,
.h4 {
    margin-top: 0;
}

h5,
.h5 {
    margin-top: 0;
}

h6,
.h6 {
    margin-top: 0;
}

.main-container img {
    max-width: 100% !important;
    height: auto !important;
}

.btn-primary {
    .button-variant-apres(#ffffff;
        @apresge_darkgreen;
        #ffffff;
        #ffffff;
        #930084;
        #ffffff);
}

.btn-select {
    .button-variant-apres(@input-color;
        @input-bg;
        @input-border;
        @input-color;
        @input-bg;
        @input-border-focus);
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option img {
    width: 16px;
    height: 16px;
    margin-right: 6px;
}

.bootstrap-select .dropdown-header {
    font-size: @font-size-large;
    font-weight: bold;
}

.tabledrag-toggle-weight-wrapper {
    display: none;
}

.panel-stripped {
    .panel-variant(@panel-primary-border;
        @panel-primary-text;
        @panel-primary-heading-bg;
        @panel-primary-border);
    border: 0 none;

    &>.panel-heading {
        >.panel-title {
            .black-font();
            text-transform: uppercase;
            font-size: @font-size-base;
        }

        +.panel-collapse {
            .background-stripped();
            background-repeat: repeat;
            background-position: left top;

            a {
                color: @gray;
                font-weight: bold;

                &:hover,
                &:focus {
                    color: @gray;
                }
            }
        }
    }
}

// Select 2
.select2-results {

    .select2-result {

        &.select2-selected {
            display: list-item;
            background-color: #ddd;
        }

        &.select2-disabled {
            background: transparent;
            color: #999;
        }
    }
}

// Layouts
#header {
    border-top: 5px solid @gray;
}

.container-header {
    position: relative;
    padding-left: 0;
    padding-right: 0;

    .navbar {
        margin-bottom: 0;
        border: 0 none;
    }

    .navbar-collapse {
        padding-left: 0;
    }

    #search-form {
        position: absolute;
        top: @navbar-height;
        background-color: @brand-primary;
        padding: @padding-large-vertical @padding-large-horizontal;
        z-index: 999;
        width: 100%;
    }

    .menu {
        font-family: @font-family-sans-serif;
        font-weight: 900;
        font-size: @font-size-small;
        text-transform: uppercase;
    }

    .navbar-toggle.pull-left {
        margin-right: 5px;
    }

    .name {
        display: none;
        padding-left: 0px;

        @media (max-width: @grid-float-breakpoint-max) {
            display: block;
        }
    }
}

#branding {
    background: #fff url('../images/bkgd_branding.jpg') right center no-repeat;
    padding-top: 15px;
    padding-bottom: 15px;

    @media screen and (max-width: @grid-float-breakpoint-max) {
        background-image: none;
    }
}

#navbar-primary {
    .secondary {
        display: none;

        @media (max-width: @grid-float-breakpoint-max) {
            display: block;
        }
    }

    @media (max-width: @grid-float-breakpoint-max) {
        padding-left: @navbar-padding-horizontal;
    }
}

#header-right {
    position: absolute;
    top: 5px;
    right: 0;

    >#search-form-toggle,
    >.dropdown {
        float: left;
        margin-left: 5px;
    }
}

ul.secondary {
    float: none;

    @media @tablet {
        float: none;
    }
}

.main-container {
    background-color: white;
    padding-top: @padding-large-vertical;
    padding-bottom: @padding-large-vertical;
}

.dropdown-menu {
    form {
        padding: @padding-base-vertical @padding-base-horizontal;
    }
}

.help-block,
.control-group .help-inline {
    font-size: @font-size-small;
}

.title {
    font-size: @font-size-base;
    font-weight: bold;
    color: @brand-primary;
    margin: 0;
    padding: 0;

    >a {
        color: inherit;
        font-weight: inherit;
        text-decoration: none;

        &:hover,
        &:focus {
            color: @link-color;
        }
    }
}

.title-sm {
    font-size: @font-size-small;
}

.title-lg {
    font-size: @font-size-large;
}

.panel-title {
    >.accordion-toggle {
        position: relative;
        display: block;
        padding-right: 20px;

        &:after {
            font-family: 'Glyphicons Halflings';
            content: "\e114";
            position: absolute;
            right: 0;
            top: 50%;
            .translate3d(0;
                -50%;
                0);
        }

        &.collapsed:after {
            content: "\e080";
        }
    }
}

.dropdown-menu {
    padding-top: 0;
    margin-top: 5px;

    >.__REMOVEDdropdown-header {
        font-size: @font-size-base;
        font-family: @font-family-sans-serif;
        font-weight: 900;

    }
}


/**
 * Panels & blocks
 */

.panel-pane-stripped-title {
    >.pane-title {
        .background-stripped();
        font-family: @font-family-sans-serif;
        font-size: @font-size-base;
        font-weight: 900;
        line-height: 35px;
        color: @gray-base;
        text-transform: uppercase;
        padding: @padding-base-vertical @padding-base-horizontal;
    }
}

.panel-pane+.panel-pane {
    margin-top: @padding-large-vertical;
    margin-bottom: @padding-large-vertical;
}

.pane-apres-organisation-portal-accordions {
    >.pane-title {
        background-color: @brand-primary;
        color: #fff;
        padding: @panel-heading-padding;
        margin-bottom: 2px;
        .black-font();
        font-size: 21px;
        line-height: 28px;
        text-transform: uppercase;
    }

    .panel-group>.panel+.panel {
        margin-top: 2px;
    }
}

.pane-events-panel-pane-list {
    >.pane-title {
        padding: 0;

        &:before {
            content: '';
            background: transparent url(../images/ico-agenda.png) left top no-repeat;
            width: 35px;
            height: 35px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.panel-stacked {
    .row-top-wrapper {
        &:after {
            .background-stripped();
            content: ' ';
            height: 4px;
            display: block;
            margin: floor((@grid-gutter-width / 2));
        }
    }
}

.pane-news-ess-panel-pane-list {
    >.pane-title {
        padding: 0;

        &:before {
            content: '';
            background: transparent url(../images/ico-news.png) left top no-repeat;
            width: 35px;
            height: 35px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.pane-jobs-offers-panel-pane-list {
    >.pane-title {
        padding: 0;

        &:before {
            content: '';
            background: transparent url(../images/ico-emploi.png) left top no-repeat;
            width: 35px;
            height: 35px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.pane-apres-core-site-description {
    padding: 15px;
    margin-top: 15px;
    border-top: 5px solid #000000;
    border-bottom: 5px solid #000000;

    >.pane-content {
        .black-font();
        font-size: @font-size-large;
        line-height: 23px;
    }
}

.pane-views-exposed,
.panel-pane-search {
    background-color: @gray-light;
    padding: @padding-large-vertical @padding-large-horizontal;
    margin-bottom: @padding-large-vertical;

    &.portal {

        .views-widget-filter-keys,
        .views-widget-filter-field_taxo_business_areas_tid {
            width: 50%;
        }
    }

    >.pane-title {
        .black-font();
        font-size: @font-size-large;
        text-transform: uppercase;
    }

    .views-exposed-widget .dropdown-toggle {
        margin-top: 0;
    }

    .help-block,
    .control-group .help-inline {
        color: #999;
    }

    &.search-full {
        .views-exposed-widget {
            float: none;
            padding-right: 0;
        }
    }
}


/**
 * Main menu block
 */

.pane-menu-tree {
    >.pane-title {
        font-size: @font-size-large;
        color: #930084;

    }

    >.pane-content {
        display: none;

        @media (min-width: @screen-sm-min) {
            display: block !important;
        }

        &.in {
            display: block;
        }
    }

    &.pane-menu-collapssible {
        background-color: @brand-primary;
        color: #fff;

        >.pane-title {
            padding: 6px 25px 6px 6px;
            color: #fff;

            &:after {
                content: "\f0d7";
                font-family: FontAwesome;
                font-style: normal;
                font-weight: normal;
                text-decoration: inherit;
                color: #fff;
                font-size: 28px;
                line-height: 1;
                position: absolute;
                top: 2px;
                right: 7px;
                z-index: 0;
            }
        }

        .nav>li>a {
            color: #fff;
        }
    }
}

.nav.menu-block>li {
    >a {
        font-weight: bold;
        color: @brand-primary;
        padding: 6px 6px 6px 14px;

        &.active-trail {
            &:before {
                content: '';
                background-color: @brand-primary;
                position: absolute;
                top: 0;
                left: 0;
                width: 7px;
                height: 100%;
            }
        }

        &.active {
            color: #000;
        }

        &:focus,
        &:hover {
            color: @gray;
        }
    }

    >ul>li>a {
        padding-left: 30px;

        &.active-trail:before {
            left: 16px;
            top: 10px;
            border-radius: 50%;
            height: 7px;
        }
    }
}

// Views
.views-exposed-form {
    .input-group-btn {
        >.btn {
            margin-top: 0;
        }
    }

    label {
        display: block;
    }

    .views-exposed-actions {
        float: right;
        clear: both;

        .views-exposed-widget {
            float: left;
            padding-right: 1em;

            &:last-child {
                padding-right: 0;
            }

            .btn {
                margin-top: 0;
            }
        }
    }
}

.views-row {
    .background-stripped();
    background-repeat: repeat-x;
    background-position: left bottom;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.view-link,
.field-name-node-link {
    font-size: @font-size-small;
    font-weight: bold;
    text-align: right;
}

.more-link {
    text-align: center;

    >a {
        font-size: @font-size-small;
        font-weight: 400;
    }
}

// Node && fields
.field {
    >.field-items {
        >.field-item {
            margin-bottom: @padding-large-vertical;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    +.field:not(.field-name-title) {
        margin-top: @padding-large-vertical;
    }
}

.form-control.form-file {
    height: auto;
}

.stripped,
.panel-pane-stripped {
    .background-stripped();
    background-repeat: repeat;
    background-position: left top;
    padding: @padding-large-vertical @padding-large-horizontal;
}

.node-type-stage {
    h1 {
        color: @brand-primary;
    }

    .field-name-field-organisations-ref a {
        font-weight: bold;
    }

    .pane-panels-mini {
        >.pane-title {
            text-transform: uppercase;
            font-size: @font-size-large;
        }

        .field-label {
            color: @gray;
        }
    }
}

.field-name-field-image,
.field-name-field-video {
    margin-bottom: 20px;
}

.field-name-field-summary {
    font-weight: bold;
    margin-bottom: 20px;
}

.node-antenne .field.field-name-field-taxo-business-areas,
.node-type-antenne .field.field-name-field-taxo-business-areas {
    >.field-items {
        overflow: hidden;

        >.field-item {
            float: left;
            margin-right: @padding-base-vertical;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.field-name-field-taxo-services-products {
    color: @gray-lighter;
    font-size: @font-size-small;
}

.field-name-apres-stage-organisation-title {
    color: @gray;
    font-weight: bold;
}

.field-name-post-date,
.field-name-field-date-repeat {
    font-weight: bold;
}

.view-mode-card:not(.node-event),
.view-mode-list {

    .field-name-post-date,
    .field-name-field-date-repeat {
        .post-date-inline();
    }

    .field-name-title {
        .title-inline();
    }
}

.field-name-field-attachments-files {
    .file-widget.input-group {
        display: block;
    }
}

.node-antenne-crieteria-ok {
    .field-name-title {
        h2:after {
            content: '';
            background: transparent url(../images/criteres_ok.png) left top no-repeat;
            width: 18px;
            height: 18px;
            margin-left: 5px;
            display: inline-block;
            vertical-align: top;
        }
    }
}

.node-galerie-images.view-mode-full .field-name-field-images {
    >.field-items {
        .clearfix();

        >.field-item {
            float: left;
            width: 25%;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}


/**
 * Newsletter
 */

body.node-type-simplenews .region-content {
    max-width: 680px;
    margin: 0 auto;
}

.group-sn-campagne {
    padding: @padding-large-vertical;
    background: @gray-lighter;
}

.field-sn-ref {
    .field-label {
        border-bottom: 4px solid #930084;
        font-size: 18px;
        font-weight: bold;
        line-height: 22px;
        margin: 0 0 10px;
        padding: 0 0 5px;
        /*text-transform: uppercase;*/
        margin-top: 50px;
    }

    .more-link {
        border-top: 3px dotted @gray-lighter;
        margin-top: @padding-large-vertical;
        padding-top: @padding-base-vertical;
    }
}

.field-widget-options-buttons .form-checkboxes {
    overflow: auto;
    max-height: 320px;
    border: 1px solid @input-border;
    border-radius: @input-border-radius;
    padding: @padding-base-vertical @padding-base-horizontal;
}

.terms_of_use_text {
    overflow-y: auto;
    max-height: 320px;
    padding-right: 15px;
}

.footer {
    color: @gray;
    line-height: @line-height-computed;
    border: 0 none;
    padding-top: @padding-large-vertical;
    margin-top: @padding-large-vertical;

    .block {
        margin-bottom: @padding-large-vertical;

        .block-title {
            .black-font();
            font-size: @font-size-large;
            text-transform: uppercase;
            color: @gray;
        }
    }

    a {
        color: inherit;
        font-weight: bold;

        &.btn-follow {
            .button-variant-apres(#ffffff;
                @gray;
                @gray;
                #ffffff;
                @gray;
                @gray);

            +.btn-follow {
                margin-left: 5px;
            }
        }
    }

    .nav>li>a {
        padding: 0 0 6px;
    }
}

#block-apres-core-address {

    address,
    .tel,
    .contact {
        position: relative;
        display: block;
        padding-left: 20px;
        margin-bottom: @padding-large-vertical;
        font-weight: normal;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: @font-size-large;
            margin: 0;
            text-decoration: none;
            color: @gray;
        }
    }

    address:before {
        content: '\f041';
    }

    .tel:before {
        content: '\f095';
    }

    .contact:before {
        content: '\f0e0';
    }
}

.search-results {
    .search-result {
        margin: 0 0 @padding-large-vertical @padding-large-horizontal;
        padding: 0 0 @padding-large-vertical;
        border-bottom: 1px solid @gray;
    }

    .search-snippet-info {
        margin: @padding-large-vertical @padding-large-horizontal;
    }
}

@media print {
    #branding {
        display: block !important;
    }
}
